<template>
	<div>
		<confirm ref="confirm"></confirm>
		<div>
			<vue-headful v-if="offerDetails" :title="offerDetails.Title" />
			<v-breadcrumbs :items="breadcrumbsItems">
				<template v-slot:divider>
					<v-icon class="secondary--text text--lighten-2"
						>fas fa-caret-right</v-icon
					>
				</template>
			</v-breadcrumbs>

			<v-alert text type="info" dismissible v-if="offerDetails">
				<span class="font-weight-bold">{{ $t("titleSteps") }}</span>
				<br />
				<template>
					{{ $t("offerStep1") }}
					<br />
					{{ $t("offerStep2") }}
					<br />
					{{ $t("offerStep3") }}
				</template>
			</v-alert>
			<v-progress-linear
				indeterminate
				v-if="LoadingOfferDetails"
			></v-progress-linear>
			<div class="my-2 pa-2" v-if="offerDetails">
				<v-container>
					<v-row>
						<v-col
							v-for="(file, index) in offerDetails.SGPOfferItems"
							:key="file.src"
							cols="12"
							md="6"
							lg="2"
						>
							<v-card>
								<v-card-text>
									<OfferImage
										v-if="file.MediaTypeId === 1"
										@click="ShowGalleryDialog(index)"
										:file="file"
										:addSasToken="false"
									>
										<!--										<div class="ArtistInfo" v-if="file.SgpApprovalOfferItem">-->
										<!--											<ApproveStatusIcon-->
										<!--												:size="18"-->
										<!--												:approve-status="-->
										<!--													file.SgpApprovalOfferItem.ApprovalStatus-->
										<!--												"-->
										<!--											></ApproveStatusIcon>-->
										<!--											{{ file.SgpApprovalOfferItem.RetouchingNotes }}-->
										<!--										</div>-->
									</OfferImage>
									<VimeoThumbnail
										v-else
										@click="ShowGalleryDialog(index)"
										:src="file.ExternalPreviewUrl"
										:alt="file.FileName"
									>
									</VimeoThumbnail>
									<v-radio-group v-model="file.approvalStatus">
										<v-radio
											label="Approve"
											color="green"
											:value="SgpApprovalOfferItemStatusEnum.APPROVED"
										></v-radio>
										<v-radio
											label="Reject"
											color="red"
											:value="SgpApprovalOfferItemStatusEnum.REJECTED"
										></v-radio>
										<v-radio
											:value="
												SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED
											"
											label="Request Retouching"
											color="warning"
										></v-radio>
									</v-radio-group>
									<v-textarea
										v-model="file.retouchingNotes"
										v-if="
											file.approvalStatus ===
												SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED
										"
										label="Please describe the desired retouching"
										auto-grow
										rows="2"
									>
									</v-textarea>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>

				<OfferGalleryDialog
					:Files="offerDetails.SGPOfferItems"
					:OfferKey="OfferKey"
					v-model="ShowGallery"
					v-bind:carouselCurrentIndex="carouselCurrentIndex"
					@carousel-current-index-changed="OnCarouselIndexChanged($event)"
					@approveStatusUpdated="loadOfferDetails"
				></OfferGalleryDialog>

				<div class="my-5 text-center" v-if="offerDetails">
					<v-btn
						class="primary white--text mb-3"
						large
						@click="OnAcceptClick"
						:loading="sendingRequest"
					>
						<v-icon left>fas fa-check</v-icon>
						{{ "Submit" }}
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

import OfferGalleryDialog from "./ApproveOfferGalleryDialog";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail";
import OfferImage from "@/components/Shared/UI/OfferImage";
import Confirm from "@/components/Shared/Common/Confirm";

import { SgpOfferTypeEnum } from "../Events/components/sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "../Events/components/sgpOfferStatus";
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";

export default {
	components: {
		OfferGalleryDialog,
		VimeoThumbnail,
		OfferImage,
		Confirm
	},
	data() {
		return {
			offerDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/"
				}
			],

			LoadingOfferDetails: false,
			showPhotoGrid: false,

			ShowGallery: false,
			carouselCurrentIndex: 0,

			sendingRequest: false,
			showRetouchingDialog: false,
			retouchingNotes: null
		};
	},

	computed: {
		OfferKey() {
			return this.$route.params.offerKey;
		}
	},
	watch: {},
	created() {
		this.OffersService = new CrudClient("SGP/ApproverOffers");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
		this.SgpApprovalOfferItemStatusEnum = SgpApprovalOfferItemStatusEnum;
	},
	async mounted() {
		await this.loadOfferDetails();

		this.breadcrumbsItems.push({
			text: this.$t("sgp.offers"),
			disabled: false,
			exact: true,
			to: "/Clients/Offers"
		});

		// this.breadcrumbsItems.push({
		// 	text: this.offerDetails.Title,
		// 	disabled: true,
		// 	exact: true,
		// 	to: "/Clients/Offers/" + this.OfferId,
		// });
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		ShowGalleryDialog(index) {
			this.ShowGallery = true;
			this.carouselCurrentIndex = index;
		},
		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},

		async loadOfferDetails() {
			try {
				this.LoadingOfferDetails = true;
				this.offerDetails = await this.OffersService.GetSelectedFields(
					this.OfferKey,
					"*, SGPOfferStatus.*,SGPOfferItems.*, SGPOfferItems.SgpApprovalOfferItem.*"
				);

				// Order Items by file name
				this.offerDetails.SGPOfferItems = this.offerDetails.SGPOfferItems.sort(
					(a, b) =>
						a.FileName > b.FileName ? 1 : b.FileName > a.FileName ? -1 : 0
				);

				this.$log.debug("SGPOfferItems sorted");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });

				return;
			} finally {
				this.LoadingOfferDetails = false;
			}

			this.offerDetails.SGPOfferItems.forEach(oi => {
				this.$set(oi, "approvalStatus", null);
				this.$set(oi, "retouchingNotes", null);
			});
		},

		isBlank(str) {
			return !str || /^\s*$/.test(str);
		},
		async OnAcceptClick() {
			const hasPendingApprovals = this.offerDetails.SGPOfferItems.find(
				f => f.approvalStatus === null
			);
			if (hasPendingApprovals) {
				this.snackError({
					Text: "Please indicate your preferences to all photos."
				});
				return;
			}

			const hasRetouchRequestsWithoutText = this.offerDetails.SGPOfferItems.find(
				f =>
					f.approvalStatus ===
						SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED &&
					this.isBlank(f.retouchingNotes)
			);
			if (hasRetouchRequestsWithoutText) {
				this.snackError({
					Text: "For retouching requests please describe the desired edits."
				});
				return;
			}

			this.sendingRequest = true;

			try {
				for (const item of this.offerDetails.SGPOfferItems) {
					let urlPrefix;
					let dto = null;
					switch (item.approvalStatus) {
						case 1:
							urlPrefix = "Approve";
							break;
						case 2:
							urlPrefix = "Reject";
							break;
						case 3:
							urlPrefix = "RequestRetouching";
							dto = { Notes: item.retouchingNotes };
							break;
					}
					await this.OffersService.Post(
						this.OfferKey + "/" + item.MediaId,
						dto,
						urlPrefix,
						false
					);
				}
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			const itemCount = this.offerDetails.length;
			this.snackSuccess({
				Text: this.$tc("successAccept", itemCount, { count: itemCount })
			});

			await this.OffersService.Post(this.OfferKey, null, "Submit", false);
		}
	}
};
</script>
<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;
	&:hover {
		filter: brightness(80%);
	}
}

.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.8;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}
</style>
<i18n>
{
	"it": {
		"pageTitle": "Dettaglio Proposta",
		"termsError": "Devi accettare i termini e le condizioni",
		"titleSteps": "Come funziona?",

		"offerStep1": "1. Per favore selezionate la vostra scelta per ciascuna foto",
		"offerStep2": "2. Se desiderate che le immagini vengano ritoccate, descrivete nel riquadro",
		"offerStep3": "3. Cliccate sul pulsante “Invia” per confermare le vostre scelte",

		"revokedOffer": "La proposta non è più attiva.",
		"offerReceived": "Ricevuto il ",
		"proceedToOrder": "Procedi all'ordine",
		"proceed": "Procedi",
		"successAccept":"Hai approvato {count} foto | Hai approvato {count} foto",
		"successReject": "Hai rifiutato {count} foto | Hai rifiutato {count} foto",
		"successRetouchingRequest": "Retouching request for {count} photo is sent | Retouching request for {count} photos is sent"
	},
	"en": {
		"pageTitle": "Offer Details",
		"titleSteps": "How does it work?",
		"offerStep1": "1. Please select your choice for each photo",
		"offerStep2": "2. If you would like the images to be retouched please describe in the box",
		"offerStep3": "3. Click on the button “Submit” to confirm your choices",
		"revokedOffer": "The offer is no longer active.",
		"offerReceived": "Received on ",
		"proceedToOrder": "Proceed to order",
		"proceed": "Proceed",
		"successAccept":"You have approved {count} photo | You have approved {count} photos",
		"successReject": "You have rejected {count} photo | You have rejected {count} photos",
		"successRetouchingRequest": "Retouching request for {count} photo is sent | Retouching request for {count} photos is sent"
	}
}
</i18n>
